export class BootstrapFormValidationRenderer {
    render(instruction) {
        for (let { result, elements } of instruction.unrender) {
            for (let element of elements) {
                this.remove(element, result);
            }
        }

        for (let { result, elements } of instruction.render) {
            for (let element of elements) {
                this.add(element, result);
            }
        }
    }

    add(element, result) {
        try {
            const formGroup = element.closest('div');
            if (!formGroup) return;

            if (result.valid) {
                if (!formGroup.classList.contains('has-error')) {
                    formGroup.classList.add('has-success');
                }
            } else {
                // add the has-error class to the enclosing lpfn-form-group div
                formGroup.classList.remove('has-success');
                formGroup.classList.add('has-error');

                // add help-block
                const message = document.createElement('span');
                message.className = 'help-block validation-message';
                message.textContent = result.message;
                message.id = `validation-message-${result.id}`;
                formGroup.appendChild(message);
            }
        } catch (err) { console.log(err); }
    }

    remove(element, result) {
        try {
            const formGroup = element.closest('div');
            if (!formGroup) return;

            if (result.valid) {
                if (formGroup.classList.contains('has-success')) {
                    formGroup.classList.remove('has-success');
                }
            } else {
                // remove help-block
                const message = formGroup.querySelector(`#validation-message-${result.id}`);
                if (message) {
                    formGroup.removeChild(message);

                    // remove the has-error class from the enclosing lpfn-form-group div
                    if (formGroup.querySelectorAll('.help-block.validation-message').length === 0) {
                        formGroup.classList.remove('has-error');
                    }
                }
            }
        } catch (err) { console.log(err); }
    }
}
