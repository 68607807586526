import {inject} from 'aurelia-framework';
import {Router} from 'aurelia-router';
import {Security} from './common/security';
import {Api} from 'common/server';
import {Notifier, Page} from 'common/ui';
import {NewInstance} from 'aurelia-dependency-injection';
import {ValidationRules, ValidationController} from 'aurelia-validation';
import {BootstrapFormValidationRenderer} from 'validation/bootstrap-form-validation-renderer';

@inject(Router, Security, Api, Notifier, Page, NewInstance.of(ValidationController))
export class WritingAgentAgreement {

	email = '';
	proveHuman = '';
	isProcessing = false;
	hasValidationErrors = false;

	constructor(router, security, api, notifier, page, validationController) {
		this.router = router;
		this.security = security;
		this.api = api;
		this.notifier = notifier;
		this.page = page;
		this.validationController = validationController;
		this.validationController.addRenderer(new BootstrapFormValidationRenderer());

		ValidationRules
            .ensure('email').required().email()
            .on(this);
	}

	activate(params) {
		this.page.track('writing-agent-agreement');
	}

	agree() {
		var me = this;
		if (me.isProcessing) return;
		if (this.proveHuman.length > 0) return this.router.navigate("", true);

		me.validationController.validate().then(v => {
		    if (!v.valid) return;

		    me.isProcessing = true;

		    me.api.postData("member/agree-to-terms", { email: me.email }).then(data => {
		        me.notifier.success('register-agree-to-terms-confirmation-message');
		        return me.router.navigate("", true);
		    }).catch(error => {
		        me.notifier.generalError();
		        me.page.scrollToTop();
		    }).then(() => {
		        me.isProcessing = false;
		    });
		});
	}
}
