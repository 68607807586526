import { ValidationRules } from 'aurelia-validation';

export function setupValidationRules() {
    ValidationRules.customRule(
      'digits',
      (value, obj) => {
          value === null || value === undefined || value.length === 0 || /^[0-9]+$/.test(value)
      },
      `\${$displayName} must be digits.`
    );
    ValidationRules.customRule(
        'integerRange', 
        (value, obj, min, max) => {
            return value === null || value === undefined || value === '' || Number(value) !== NaN && Number.isInteger(Number(value)) && Number(value) >= min && Number(value) <= max
        },
        `\${$displayName} must be an integer between \${$config.min} and \${$config.max}.`,
        (min, max) => ({ min, max })
    );
    ValidationRules.customRule(
        'decimalRange', 
        (value, obj, min, max) => {
            return value === null || value === undefined || value === '' || Number(value) !== NaN && Number(value) >= min && Number(value) <= max
        },
        `\${$displayName} must be an integer between \${$config.min} and \${$config.max}.`,
        (min, max) => ({ min, max })
    );
    ValidationRules.customRule(
        'contractLevelRange', 
        (value, obj, min, max) => {
            if (value === null || value === undefined || value === '') return true;
            if (Number(value) === NaN) return false;
            if (!Number.isInteger(Number(value))) return false;
            if (Number(value) < min || Number(value) > max) return false;
            return Number(value) % 5 === 0;
        },
        `\${$displayName} must be an integer between \${$config.min} and \${$config.max}.`,
        (min, max) => ({ min, max })
    );
    ValidationRules.customRule(
        'matchesProperty',
        (value, obj, otherPropertyName) => {
            let otherValue = obj[otherPropertyName];
            if (!otherValue && !value && otherValue !== 0 && otherValue !== 0) return true;
            return value === otherValue;
        },
        '\${$displayName} must match',
        (otherPropertyName) => ({ otherPropertyName }),
    );
    ValidationRules.customRule(
        'slug',
        (value, obj) => {
            if (value === null || value === undefined || value.length === 0) return true;
            const passed = /^[0-9A-Za-z\-]+$/.test(value);
            return passed;
        },
        `\${$displayName} must be letters, numbers and dashes.`
    );
    ValidationRules.customRule(
        'website',
        (value, obj) => {
            if (value === null || value === undefined || value.length === 0) return true;
            if (value.indexOf('http://') < 0 && value.indexOf('https://') < 0) return false;
            return true;
        },
        `\${$displayName} must start with http:// or https://`
    );
    ValidationRules.customRule(
        'phone',
        (value, obj) => {
            if (value === null || value === undefined || value.length === 0) return true;
            const passed = value.match(/\d/g).length === 10;
            return passed;
        },
        `\${$displayName} must contain 10 digits and may include formatting.`
    );
}
